<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <tiempo-form
          :id="null"
          :idempleado="usuarioIdempleado"
          :geolocalizacion="geolocalizacion"
          :has-perm-introducir-tiempos-mas-alla-fecha-actual="hasViewPerm(permissions.tiempos.introducirTiemposMasAllaFechaActual)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import TiempoForm from './components/TiempoForm'
import Data from './TiempoAddData'
import { captureGeolocation } from '@/utils/maps'
import { toDate, parseHHmm } from '@/utils/date'
import { get } from 'vuex-pathify'

export default {
  components: {
    TiempoForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      geolocalizacion: {},
    }
  },
  computed: {
    usuarioIdempleado: get('usuario/idempleado'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Añadir tiempo trabajado'
      this.geolocalizacion = await captureGeolocation(this)
    },
    async submitForm () {
      if (this.geolocalizacion && this.geolocalizacion.lat && this.geolocalizacion.lng) {
        const ffin = parseHHmm(this.formData.hora_fin, toDate(this.formData.finicio))
        await Data.insert(this, this.formData, this.usuarioIdempleado, ffin)
        this.$appRouter.go(-1)
      } else {
        this.$alert.showSnackbarError('No se puede obtener la geolocalización del dispositivo. Asegúrate que has permitido el acceso a tu localización')
      }
    },
  }
}
</script>
