<template>
  <b10-base>
    <b10-date-time-picker
      v-model="form.finicio"
      title="Inicio"
      :rules="formRules.finicio"
    />
    <b10-time-picker
      v-model="form.hora_fin"
      title="Hora de fin"
      :rules="formRules.hora_fin"
    />
    <v-text-field
      v-model="form.kilometros"
      label="Kilómetros"
      clearable
      type="number"
      @input="changeKilometros"
    />
    <v-checkbox
      v-model="form.es_desplazamiento"
      label="Es desplazamiento"
      @change="changeEsDesplazamiento"
    />
    <v-autocomplete
      v-model="form.idvehiculo"
      :disabled="!form.es_desplazamiento"
      label="Vehiculo"
      :items="formattedVehiculos"
      item-value="idvehiculo"
      item-text="title"
      clearable
    />
    <v-autocomplete
      v-model="form.idttiempo_trabajado"
      label="Tipo de tarea"
      :items="tipoTiempoTrabajado"
      item-value="idttiempo_trabajado"
      item-text="descripcion"
      clearable
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <v-text-field
      v-model.number="form.latitud"
      label="Latitud"
      disabled
    />
    <v-text-field
      v-model.number="form.longitud"
      label="Longitud"
      disabled
    />
    <v-btn
      v-show="form.latitud && form.longitud"
      block
      color="secondary"
      dark
      @click.stop="clickIrMapa(form.latitud, form.longitud)"
    >
      <v-icon
        left
      >
        {{ $vuetify.icons.values.mapLocation }}
      </v-icon> Localización
    </v-btn>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import _ from '@/utils/lodash'
import Data from './TiempoFormData'
import { PARAMETRO } from '@/utils/consts'
import { getLatLongURL } from '@/utils/maps'
import { currentDateTime, parseHHmm, toDate, addSeconds } from '@/utils/date'

export default {
  mixins: [formMixin],
  props: {
    idempleado: {
      type: Number,
      required: true,
    },
    hasPermIntroducirTiemposMasAllaFechaActual: {
      type: Boolean,
      default: false,
    },
    geolocalizacion: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      form: {
        finicio: null,
        hora_fin: null,
        kilometros: 0,
        es_desplazamiento: false,
        observaciones: null,
        latitud: null,
        longitud: null,
        idvehiculo: null,
        idttiempo_trabajado: null,
      },
      formRules: {
        finicio: [
          v => !!v || 'Campo requerido'
        ],
        hora_fin: [
          v => this.horasFinValida(v)
        ],
      },
      vehiculos: [],
      tipoTiempoTrabajado: [],
    }
  },
  computed: {
    formattedVehiculos () {
      return _.map(this.vehiculos, (item) => {
        item.title = `${item.descripcion} (${item.matricula})`
        return item
      })
    },
  },
  watch: {
    geolocalizacion: {
      handler: function (newValue) {
        if (newValue) {
          this.$set(this.form, 'latitud', newValue.lat)
          this.$set(this.form, 'longitud', newValue.lng)
        }
      },
      deep: true,
    },
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.vehiculos = resp.data.selectVehiculo.result.dataset
    this.changeEsDesplazamiento(this.esDesplazamiento)
    this.tipoTiempoTrabajado = resp.data.selectTtiempoTrabajado.result.dataset
    const idttiempoTrabajado = await this.$online.parametro.valorParametro(PARAMETRO.parametros.IDTTIEMPO_TRA_DEFECTO)
    if (idttiempoTrabajado) {
      this.$set(this.form, 'idttiempo_trabajado', parseInt(idttiempoTrabajado))
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    async changeKilometros (value) {
      if (value > 0) {
        this.$set(this.form, 'es_desplazamiento', true)
      } else {
        this.$set(this.form, 'es_desplazamiento', false)
      }
      this.changeEsDesplazamiento(this.form.es_desplazamiento)
    },
    horasFinValida (value) {
      if (value) {
        if (this.form.finicio) {
          // sumar 59 segundos a la fecha fin para que permita loguear en el mismo minuto
          const fin = addSeconds(parseHHmm(this.form.hora_fin, toDate(this.form.finicio)), 59)
          if (this.form.finicio <= fin) {
            if (fin > addSeconds(currentDateTime(), 59)) {
              if (this.hasPermIntroducirTiemposMasAllaFechaActual) {
                return true
              } else {
                return 'El tiempo trabajado va mas allá del momento actual'
              }
            } else {
              return true
            }
          } else {
            return 'La hora de fin debe ser posterior a la de inicio'
          }
        } else {
          // sin fecha de inicio no se puede validar
          return true
        }
      } else {
        return 'Campo requerido'
      }
    },
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
    changeEsDesplazamiento(value) {
      if (value) {
        const vehiculoEmpleado = _.find(this.vehiculos, { idempleado: this.idempleado })
        if (vehiculoEmpleado) {
          this.$set(this.form, 'idvehiculo', vehiculoEmpleado.idvehiculo)
        }
      } else {
        this.$set(this.form, 'idvehiculo', null)
      }
    }
  }
}
</script>
