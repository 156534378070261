import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectVehiculo', method: 'vehiculo.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTtiempoTrabajado', method: 'ttiempoTrabajado.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
}
